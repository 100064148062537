import React from "react"
import kebabCase from "lodash"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../config/typography"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const tags = post.frontmatter.tags || []

  const site = data.site.siteMetadata.main
  const { previous, next } = pageContext

  const articleTitleFont = "Clear Sans"
  const articleBodyFont = "Open Sans"
  const articleMetaFont = "Open Sans"

  return (
    <Layout location={location} site={site}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
              fontFamily: `${articleTitleFont}`,
              fontWeight: 500,
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              fontFamily: `${articleMetaFont}`,
              fontWeight: 500,
              display: `block`,
              marginBottom: 0,
            }}
          >
            {post.frontmatter.date}
          </p>
          <ul
            style={{
              ...scale(-1 / 5),
              fontFamily: `${articleMetaFont}`,
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `flex-start`,
              listStyle: `none`,
              marginBottom: rhythm(0),
            }}
          >
            {tags.map(t => (
              <li key={kebabCase(t)} style={{ paddingRight: 10 }}>
                <Link to={`/tags/${kebabCase(t)}`}>{t}</Link>
              </li>
            ))}
          </ul>
        </header>
        <section
          style={{
            fontFamily: `${articleBodyFont}`,
          }}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />

        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <Bio />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
            fontFamily: `${articleTitleFont}`,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        main {
          title
          description
          siteUrl
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
  }
`
